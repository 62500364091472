  
import axiosClient from './axiosClient';
import { serviceBase } from './serviceBase';

export const getProductsService = serviceBase({
	request: () => axiosClient.get('product')
});

export const getCategoriesService = serviceBase({
	request: () => axiosClient.get('categories')
});

export const addUserToStockList = serviceBase({
	request: (values) => axiosClient.patch('product/stock/emails', {...values})
});


export default getProductsService;